import { IEnvironment } from '@shared/core/interfaces/environment.interface';

export const environment: IEnvironment = {
  maintenanceMode: false,
  production: true,
  envName: 'prod',
  baseUrl: 'https://api.pickwings.ch',
  baseSocketUrl: 'wss://api.pickwings.ch',
  firebase: {
    apiKey: 'AIzaSyDE3GoG4Tczcj2_yhsZgUhhDOmvIeEZJWw',
    authDomain: 'pickwings-webapps.firebaseapp.com',
    projectId: 'pickwings-webapps',
    storageBucket: 'pickwings-webapps.appspot.com',
    messagingSenderId: '108292520554',
    appId: '1:108292520554:web:df276f3cf486cb83be2813',
    measurementId: 'G-RNMXW2R2CP',
  },
  isBuildInfoShown: false,
  defaultLanguage: 'de',
  showUpdateAvailable: true,
  panelType: 'carrier',
  languages: ['de', 'en'],
  relateWebsite: {
    shipper: 'https://shipper.pickwings.ch/',
    carrier: 'https://carrier.pickwings.ch/',
    about: 'https://pickwings.ch/',
    driver: 'https://driver.pickwings.ch/',
  },
};
